import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "next-translate/useTranslation";

export default function FilterDialogFull(props) {
  const { t } = useTranslation('common')
  const { onClose, open, place, ...other } = props;
  const dispatch = useDispatch();

  const reestrFilterExtended = useSelector(
    (state) => state.reestr_filter_extended
  );
  const reestrFilterType = useSelector((state) => state.reestr_filter_type);
  const reestrFilterSort = useSelector((state) => state.reestr_filter_sort);
  const reestrFilterWithPhoto = useSelector((state) => state.reestr_filter_withphoto);
  const reestrFilterCanPurchase = useSelector((state) => state.reestr_filter_canpurchase);
  const reestrFilterYear = useSelector((state) => state.reestr_filter_year);

  const mycollectionFilterExtended = useSelector(
    (state) => state.mycollection_filter_extended
  );
  const mycollectionFilterType = useSelector(
    (state) => state.mycollection_filter_type
  );
  const mycollectionFilterSort = useSelector(
    (state) => state.mycollection_filter_sort
  );
  const mycollectionFilterYear = useSelector(
    (state) => state.mycollection_filter_year
  );

  const usercollectionFilterExtended = useSelector(
    (state) => state.usercollection_filter_extended
  );
  const usercollectionFilterType = useSelector(
    (state) => state.usercollection_filter_type
  );
  const usercollectionFilterSort = useSelector(
    (state) => state.usercollection_filter_sort
  );
  const usercollectionFilterYear = useSelector(
    (state) => state.usercollection_filter_year
  );
  const usercollectionFilterCanpurchase = useSelector(
    (state) => state.usercollection_filter_canpurchase
  );
  let filterExtended='', filterType='', filterSort='', filterWithPhoto=false, filterCanPurchase=false, filterYear=false;

  if (place==='reestr') {
    filterExtended = reestrFilterExtended;
    filterType = reestrFilterType;
    filterSort = reestrFilterSort;
    filterWithPhoto=reestrFilterWithPhoto;
    filterCanPurchase=reestrFilterCanPurchase;
    filterYear=reestrFilterYear;
  }
  if (place==='mycollection') {
    filterExtended = mycollectionFilterExtended;
    filterType = mycollectionFilterType;
    filterSort = mycollectionFilterSort;
    filterYear = mycollectionFilterYear;
  }
  if (place==='usercollection') {
    filterExtended = usercollectionFilterExtended;
    filterType = usercollectionFilterType;
    filterSort = usercollectionFilterSort;
    filterYear = usercollectionFilterYear;
    filterCanPurchase = usercollectionFilterCanpurchase;
  }
  const radioGroupRef = React.useRef(null);
  const [openTypes, setOpenTypes] = useState(false);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose();
  };

  const handleChangeFilterExtended = (event) => {
    dispatch({
      type: "SET"+place.toUpperCase()+"FILTEREXTENDED",
      value: event.target.value,
    });
  };
  const handleChangeFilterSort = (event) => {
    dispatch({
      type: "SET"+place.toUpperCase()+"FILTERSORT",
      value: event.target.value,
    });
  };
  const handleChangeFilterYear = (event) => {
    dispatch({
      type: "SET"+place.toUpperCase()+"FILTERYEAR",
      value: event.target.value,
    });
  };
  const handleChangeFilterWithPhoto = (event) => {
    dispatch({
      type: "SET"+place.toUpperCase()+"FILTERWITHPHOTO",
      value: event.target.checked,
    });
  };
  const handleChangeFilterCanPurchase = (event) => {
    dispatch({
      type: "SET"+place.toUpperCase()+"FILTERCANPURCHASE",
      value: event.target.checked,
    });
  };
  const handleChangeFilterType = (event) => {
    dispatch({
      type: "SET"+place.toUpperCase()+"FILTERTYPE",
      value: event.target.value,
    });
    setOpenTypes(false);
  };

  const handleDeleteFilterType = (chipToDelete) => () => {
    //console.log("handleDelete");
    //console.log(chipToDelete);
    let newVal = filterType.filter((v) => v !== chipToDelete);
    dispatch({
      type: "SET"+place.toUpperCase()+"FILTERTYPE",
      value: newVal,
    });
  };

  const types = [
    { title: t('micro'), tip: "micro" },
    { title: t('mini'), tip: "mini" },
    { title: t('semimini'), tip: "semimini" },
    { title: t('smallstandard'), tip: "smallstandard" },
    { title: t('standard'), tip: "standard" },
    { title: t('largestandard'), tip: "largestandard" },
    { title: t('minitrailer'), tip: "minitrailer" },
    { title: t('semiminitrailer'), tip: "semiminitrailer" },
    { title: t('standardtrailer'), tip: "standardtrailer" },
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="filter-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="filter-dialog-title">{t('filter_settings')}</DialogTitle>
      <DialogContent dividers>
        {place==='reestr' && <FormControlLabel control={
          <Checkbox
            checked={filterWithPhoto}
            onChange={handleChangeFilterWithPhoto}
          />
        } label={t("with_photo")} />}
        {(place==='reestr' || place==='usercollection') && <FormControlLabel control={
          <Checkbox
            checked={filterCanPurchase}
            onChange={handleChangeFilterCanPurchase}
          />
        } label={t("can_purchase")} />}
        <FormLabel component="legend">{t("year_sel")}</FormLabel>
        <TextField
          size="small"
          margin="dense"
          sx={{paddingBottom: "10px"}}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          value={filterYear}
          onChange={handleChangeFilterYear}
          error={
                !(
                  (filterYear > 1900 && filterYear < 2030) ||
                  filterYear === 0 ||
                  filterYear === null ||
                  filterYear === ""
                )
              }
        />
        <FormLabel component="legend">{t('search_scope')}</FormLabel>
        <RadioGroup
          aria-label={t('search_scope')}
          name="filterExtended"
          value={filterExtended}
          onChange={handleChangeFilterExtended}
        >
          <FormControlLabel
            value={"false"}
            control={<Radio />}
            label={t('search_names_only')}
          />
          <FormControlLabel value={"true"} control={<Radio />} label={t('search_everywhere')} />
        </RadioGroup>
        <FormLabel component="legend" style={{ marginTop: "15px" }}>
          {t('sort')}
        </FormLabel>
        <RadioGroup
          aria-label={t('sort')}
          name="filterSort"
          value={filterSort}
          onChange={handleChangeFilterSort}
        >
          <FormControlLabel
            value={"-id"}
            control={<Radio />}
            label={t('date_created')}
          />
          <FormControlLabel
            value={"name"}
            control={<Radio />}
            label={t('alphabetically')}
          />
        </RadioGroup>
        <FormLabel component="legend" style={{ marginTop: "15px" }}>
          {t('foliage_type')}
        </FormLabel>

        <Select
          label="Тип розетки"
          style={{ width: "100%" }}
          id="type-checkbox"
          multiple
          value={filterType}
          open={openTypes}
          onOpen={()=>setOpenTypes(true)}
          onClose={()=>setOpenTypes(false)}
          onChange={handleChangeFilterType}
          input={<OutlinedInput />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={types.filter((t) => t.tip === value)[0].title}
                  onDelete={handleDeleteFilterType(value)}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {types.map((val) => (
            <MenuItem key={val.tip} value={val.tip}>
              <Checkbox checked={filterType.indexOf(val.tip) > -1} />
              <ListItemText primary={val.title} />
            </MenuItem>
          ))}
        </Select>

      </DialogContent>
      <DialogActions>
        {/*<Button autoFocus onClick={handleCancel} color="primary">
          Отменить
        </Button>*/}
        <Button autoFocus onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
